/**
 * Seeq REST API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 66.0.0-v202410130401-CD
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AxiosPromise } from 'axios';
import { getAxiosInstance, SeeqAxiosRequestConfig } from '@/requests/axios.utilities';
import { isNil, omitBy } from 'lodash';
import * as qs from 'qs';
import * as models from '../model/models';
import { APPSERVER_API_PREFIX } from '@/main/app.constants';

export class sqFoldersApiClass {
 
   constructor() {}

  /**
   * @summary Archive a folder and all of its content
   * @param {string} folderId - ID of the folder to archive
   */
  public archiveFolder(
    {
      folderId
    } : {
      folderId: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(folderId)) {
      throw new Error("'folderId' parameter required");
    }

    return getAxiosInstance()({
      method: 'delete',
      url: APPSERVER_API_PREFIX + `/folders/${encodeURIComponent(String(folderId))}`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.ArchiveOutputV1>;
  }

  /**
   * @summary Create a folder
   */
  public createFolder(
    body: models.FolderInputV1,
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/folders`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.FolderOutputV1>;
  }

  /**
   * @summary Get navigation context for a folder. 
   * @param {string} folderId - ID of the folder to retrieve.
   * @param {string} [root] - What sharing category results should be returned for
   */
  public getAncestors(
    {
      folderId,
      root
    } : {
      folderId: string,
      root?: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(folderId)) {
      throw new Error("'folderId' parameter required");
    }

    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/folders/${encodeURIComponent(String(folderId))}/ancestors`,

      params: omitBy({
        ['root']: root
      }, isNil),
      paramsSerializer: params => qs.stringify(params, { indices: false }),
      ...extraHttpRequestConfig
      }) as AxiosPromise<models.FolderNavigationOutputV1>;
  }

  /**
   * @summary Get information about a folder
   * @param {string} folderId - ID of the folder to retrieve. Special values of &#x27;mine&#x27;, &#x27;corporate&#x27;, or &#x27;users&#x27; (admins only) fetch the authenticated user&#x27;s home folder, the corporate folder, or the folder of all home folders, respectively.
   * @param {boolean} [fullAncestry] - If true, the full ancestry  will be returned, which includes the home folder of the relevant user. Otherwise, the home folder is not returned. Requires admin privileges.
   */
  public getFolder(
    {
      folderId,
      fullAncestry
    } : {
      folderId: string,
      fullAncestry?: boolean
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(folderId)) {
      throw new Error("'folderId' parameter required");
    }

    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/folders/${encodeURIComponent(String(folderId))}`,

      params: omitBy({
        ['fullAncestry']: fullAncestry
      }, isNil),
      paramsSerializer: params => qs.stringify(params, { indices: false }),
      ...extraHttpRequestConfig
      }) as AxiosPromise<models.FolderOutputV1>;
  }

  /**
   * @summary Get all root folders and workbooks, or folder content if a parent folder id is specified.
   * @param {string} [filter=all] - If set to &#x27;mine&#x27; or &#x27;owner&#x27;, only workbench items under the current user&#x27;s home folder will be returned. If set to &#x27;public&#x27; only workbench items shared with the Everyone group will be returned, excluding items under the corporate folder. If set to &#x27;sharedOrPublic&#x27; only workbench items shared with the current user or a group they belong to are returned, excluding items under the corporate folder. If set to &#x27;sharedDirectlyWithMe&#x27; only workbench items directly shared with the current user will be returned, excluding items under the corporate folder and items shared with groups the user belongs to. If set to &#x27;shared&#x27; only workbench items shared with the current user or a group they belong to (except the Everyone group) are returned, excluding items under the corporate folder. If set to &#x27;corporate&#x27;, only workbench items under the corporate folder are returned. If set to &#x27;users&#x27;, only items under other user&#x27;s home folders are returned. If set to &#x27;all&#x27; (or not specified), all workbench items the current user has access to will be returned.
   * @param {string} [userId] - The user ID to return folders for, defaults to the current user if not specified. Only an admin user is allowed to specify a user ID.
   * @param {boolean} [isArchived=false] - When true, returns only archived workbench items.
   * @param {string} [sortOrder=createdAt asc] - A field by which to order the folders followed by a space and &#x27;asc&#x27; or &#x27;desc&#x27;. Field name can be one of: createdAt, updatedAt, name, owner
   * @param {number} [offset=0] - The pagination offset, the index of the first collection item that will be returned in this page of results
   * @param {number} [limit=40] - The pagination limit, the total number of collection items that will be returned in this page of results
   * @param {boolean} [onlyPinned=false] - When true, returns only pinned workbench items are returned. The given folderId is not applied.
   * @param {string} [folderId] - The folder to return the content of. If not specified, only root folders are returned unless filtering to specific IDs, filtering to pinned or archived items, sorting by last opened timestamp, or a text search is provided.
   * @param {Array<string>} [ids] - The IDs of items to return.
   * @param {Array<string>} [types] - The types of items to return.
   * @param {string} [textSearch] - Search text by which to filter items&#x27; names.
   * @param {boolean} [isExact=false] - If true, uses the exact query terms supplied by the user instead of stemming the query to its root words
   * @param {string} [root] - The highest ancestor that items should have, if possible
   * @param {Array<string>} [searchFields] - Search by name, description, or document body.  Searches name by default when no field is supplied
   * @param {Array<string>} [creatorIds] - List of creator IDs to filter on
   * @param {Array<string>} [ownerIds] - List of owner IDs to filter on
   * @param {Array<string>} [locations] - List of locations to search under, may be &#x27;mine&#x27;, &#x27;others&#x27;, or &#x27;corporate&#x27;. &#x27;mine&#x27; searches all items under the current user&#x27;s home folder. &#x27;others&#x27; searches items under home folders belonging to all users other than the current user. &#x27;corporate&#x27; searches the corporate folder, if enabled. Values may be used together. No values searches all locations.
   * @param {Array<string>} [accessStatuses] - List of access statuses to search by, may be &#x27;public&#x27;, &#x27;groups&#x27;, or &#x27;directly&#x27;. &#x27;public&#x27; searches items shared with the Everyone group, if enabled. &#x27;groups&#x27; searches items shared with groups the current user belongs to. &#x27;directly&#x27; searches items directly accessible by the current user. No values searches all access statuses.
   */
  public getFolders(
    {
      filter,
      userId,
      isArchived,
      sortOrder,
      offset,
      limit,
      onlyPinned,
      folderId,
      ids,
      types,
      textSearch,
      isExact,
      root,
      searchFields,
      creatorIds,
      ownerIds,
      locations,
      accessStatuses
    } : {
      filter?: FilterEnum,
      userId?: string,
      isArchived?: boolean,
      sortOrder?: string,
      offset?: number,
      limit?: number,
      onlyPinned?: boolean,
      folderId?: string,
      ids?: Array<string>,
      types?: Array<string>,
      textSearch?: string,
      isExact?: boolean,
      root?: RootEnum,
      searchFields?: SearchFieldsEnum[],
      creatorIds?: Array<string>,
      ownerIds?: Array<string>,
      locations?: LocationsEnum[],
      accessStatuses?: AccessStatusesEnum[]
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/folders`,

      params: omitBy({
        ['filter']: filter,
        ['userId']: userId,
        ['isArchived']: isArchived,
        ['sortOrder']: sortOrder,
        ['offset']: offset,
        ['limit']: limit,
        ['onlyPinned']: onlyPinned,
        ['folderId']: folderId,
        ['ids']: ids,
        ['types']: types,
        ['textSearch']: textSearch,
        ['isExact']: isExact,
        ['root']: root,
        ['searchFields']: searchFields,
        ['creatorIds']: creatorIds,
        ['ownerIds']: ownerIds,
        ['locations']: locations,
        ['accessStatuses']: accessStatuses
      }, isNil),
      paramsSerializer: params => qs.stringify(params, { indices: false }),
      ...extraHttpRequestConfig
      }) as AxiosPromise<models.WorkbenchItemOutputListV1>;
  }

  /**
   * @summary Get subfolders of a folder. 
   * @param {string} folderId - ID of the folder to retrieve. Special values of &#x27;mine&#x27;, &#x27;corporate&#x27;, &#x27;sharedOrPublic&#x27;, or &#x27;users&#x27; can be used to fetch the top level categories.
   * @param {string} [root] - What sharing category results should be returned for
   */
  public getSubfolders(
    {
      folderId,
      root
    } : {
      folderId: string,
      root?: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(folderId)) {
      throw new Error("'folderId' parameter required");
    }

    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/folders/${encodeURIComponent(String(folderId))}/subfolders`,

      params: omitBy({
        ['root']: root
      }, isNil),
      paramsSerializer: params => qs.stringify(params, { indices: false }),
      ...extraHttpRequestConfig
      }) as AxiosPromise<models.FolderNavigationOutputV1>;
  }

  /**
   * @summary Move specified item to specified folder
   * @param {string} folderId - ID of folder to move item to. Special values of &#x27;mine&#x27; or &#x27;corporate&#x27; move the item to the authenticated user&#x27;s home folder or the corporate folder, respectively.
   * @param {string} itemId - ID of item to move to folder
   */
  public moveItemToFolder(
    {
      folderId,
      itemId
    } : {
      folderId: string,
      itemId: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(folderId)) {
      throw new Error("'folderId' parameter required");
    }

    if (isNil(itemId)) {
      throw new Error("'itemId' parameter required");
    }

    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/folders/${encodeURIComponent(String(folderId))}/${encodeURIComponent(String(itemId))}`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.FolderOutputV1>;
  }

  /**
   * @summary Remove specified item from specified folder. The item will be moved to the root level.
   * @param {string} folderId - ID of folder to remove item from
   * @param {string} itemId - ID of item to remove from folder
   */
  public removeItemFromFolder(
    {
      folderId,
      itemId
    } : {
      folderId: string,
      itemId: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(folderId)) {
      throw new Error("'folderId' parameter required");
    }

    if (isNil(itemId)) {
      throw new Error("'itemId' parameter required");
    }

    return getAxiosInstance()({
      method: 'delete',
      url: APPSERVER_API_PREFIX + `/folders/${encodeURIComponent(String(folderId))}/${encodeURIComponent(String(itemId))}`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.FolderOutputV1>;
  }

}

export enum FilterEnum {
    Mine = 'Mine' as any,
    Owner = 'Owner' as any,
    Public = 'Public' as any,
    SharedOrPublic = 'SharedOrPublic' as any,
    SharedDirectlyWithMe = 'SharedDirectlyWithMe' as any,
    Shared = 'Shared' as any,
    Corporate = 'Corporate' as any,
    Users = 'Users' as any,
    All = 'All' as any
}
export enum RootEnum {
    Mine = 'Mine' as any,
    Shared = 'Shared' as any,
    Corporate = 'Corporate' as any,
    Users = 'Users' as any
}
export enum SearchFieldsEnum {
    All = 'All' as any,
    Name = 'Name' as any,
    Description = 'Description' as any,
    Document = 'Document' as any
}
export enum LocationsEnum {
    Mine = 'Mine' as any,
    Others = 'Others' as any,
    Corporate = 'Corporate' as any
}
export enum AccessStatusesEnum {
    Public = 'Public' as any,
    Groups = 'Groups' as any,
    Directly = 'Directly' as any
}

export const sqFoldersApi = new sqFoldersApiClass();
